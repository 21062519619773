import axios from 'axios'

class student_bills_invoice_api {
  async get({ student_id, is_deleted, isPaid, study_year }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')

    const response = await axios.get(
      `/counting/student_salary/invoice/student_id/${student_id}?is_deleted=${is_deleted}&isPaid=${isPaid}&study_year=${study_year}`,
    )

    return response
  }

  async add({ student_id, service_type, payments, desc, invoice_number, study_year, date }) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    const response = await axios.post('/counting/student_salary/invoice', {
      student_id,
      service_type,
      date,
      payments,
      study_year,
      invoice_number,
      desc,
    })

    return response
  }

  async remove(id) {
    axios.defaults.headers.common.Authorization = localStorage.getItem('accessToken')
    const response = await axios.delete(`/counting/student_salary/invoice/invoice_id/${id}`)

    return response
  }
}

export default new student_bills_invoice_api()
